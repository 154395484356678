<template>
  <div class="address">
    <center-title title="收货地址" :border="false"></center-title>
    <div class="add-box">
      <div class="add-title">{{ type == "add" ? "新增" : "修改" }}收货地址</div>
      <div class="add-info xs">
        <add-address
          :type="type"
          :id="id"
          @successAddress="$router.go(-1)"
        ></add-address>
      </div>
    </div>
  </div>
</template>
<script>
import CenterTitle from "../components/CenterTitle";
import AddAddress from "./components/AddAddress";
export default {
  name: "",
  props: {},
  components: {
    "center-title": CenterTitle,
    "add-address": AddAddress,
  },
  data() {
    return {
      type: "add",
      id: null,
    };
  },
  methods: {},
  computed: {},
  watch: {},
  created() {
    this.type = this.$route.query.type;
    this.id = this.$route.query.id;
  },
  mounted() {},
  beforeDestroy() {},
};
</script>
<style lang="scss" scoped>
.add-box {
  border: $-solid-border;
  padding: 0 15px;
  .add-title {
    height: 68px;
    line-height: 68px;
    padding-left: 13px;
    border-bottom: $-solid-border;
  }
  .add-info {
    padding: 33px 157px 42px 0;
  }
}
</style>
